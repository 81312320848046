import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Loader } from '../../components/Loader';
import { MODAL_ANCHOR_ID } from '../../constants/base';
import { useLoader } from '../../hooks/useLoader';
import { useGetClientMenuMainQuery } from '../../redux/api/menu';
import { ReactComponent as Logo } from '../../static/logo.svg';

import ButtonBlock from './components/Main/ButtonBlock';
import MenuHeader from './components/Main/Header';
import ImagesBG from './components/Main/ImagesBg';
import { LanguageSelect } from './shared/LanguageSelect';
import Switch from './shared/Switch';

import './shared/style.css';

export const ClientMenu = () => {
  const { hash } = useParams();
  const { data, isLoading } = useGetClientMenuMainQuery(hash as string);
  const {
    i18n: { language },
  } = useTranslation();

  const isLoader = useLoader(isLoading);

  return (
    <main
      className={`dark:bg-none dark:bg-darkBg bg-[#F3F3F3] h-full pb-[10vw] relative overflow-x-hidden ${
        language !== 'ge' ? 'font-sourceSans' : 'font-helvetica'
      }`}
      id={MODAL_ANCHOR_ID}
    >
      {isLoader && <Loader />}
      <MenuHeader className="mb-[9.6vw] z-50 px-[15px]">
        <Switch />
        <Logo className="translate-x-[12px]" />
        {data ? (
          <LanguageSelect
            items={data.activeLanguages}
            className="!bg-transparent"
            defaultLanguage={data.defaultLanguage}
          />
        ) : (
          <div className="py-[2.113vw] h-[40px] px-[44px] max-w-[93px] min-w-[93px] w-[93px]"></div>
        )}
      </MenuHeader>
      <ImagesBG />
      <ButtonBlock
        image={data?.user.image}
        qrLink={data?.user.qrLink || ''}
        feedbackLink={data?.user.feedbackLink || ''}
        menuLink={data?.user.menuLink || ''}
      />
    </main>
  );
};
