import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Loader } from '../../components/Loader';
import { API_URL } from '../../constants/api';
import { MODAL_ANCHOR_ID } from '../../constants/base';
import { useLoader } from '../../hooks/useLoader';
import { useGetClientMenuCategoryQuery } from '../../redux/api/menu';
import { useAppSelector } from '../../redux/hooks';
import { ReactComponent as Logo } from '../../static/logo.svg';

import CategoryCard from './components/Categories/CategoryCard';
// import Slider from './components/Categories/Slider';
import Header from './components/Main/Header';
import { LanguageSelect } from './shared/LanguageSelect';
import Switch from './shared/Switch';
import Touchbar from './shared/Touchbar';

import './shared/style.css';

const Categories = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { hash } = useParams();
  const { menuLang } = useAppSelector(state => state.user);
  const { data, isLoading, isFetching, refetch } = useGetClientMenuCategoryQuery({
    lang: menuLang || language,
    hash: hash as string,
  });

  const isLoader = useLoader(isLoading, isFetching);

  return (
    <main
      className={`dark:bg-none dark:bg-darkBg bg-categoryBg h-full relative overflow-x-hidden pb-[21vw] ${
        language !== 'ge' ? 'font-sourceSans' : 'font-helvetica'
      }`}
      id={MODAL_ANCHOR_ID}
    >
      <div className="px-[15px]">
        {isLoader && <Loader />}
        <Header className="mb-[12px] px-0">
          <Switch />
          <Link to={`/menu/${hash}`} className="translate-x-[12px]">
            <Logo />
          </Link>
          {data ? (
            <LanguageSelect
              items={data.activeLanguages}
              className="!bg-transparent"
              refetch={refetch}
              defaultLanguage={data.defaultLanguage}
            />
          ) : (
            <div className="py-[2.113vw] h-[40px] px-[44px] max-w-[93px] min-w-[93px] w-[93px]"></div>
          )}
        </Header>
        {data?.menuPromoImage && (
          <div className="w-full h-[35vw] rounded-[16px] overflow-hidden mb-[12px]">
            <img
              src={`${API_URL}/image/${data.menuPromoImage}`}
              className="w-full h-full object-cover"
            />
          </div>
        )}
        <h2 className="dark:text-white text-[28px] leading-[32px] font-bold mb-[18px] text-blackBg">
          {t('foodMenu.client.categoryTitle')}
        </h2>
        {data && (
          <div className="flex flex-wrap gap-[10px] justify-between">
            {data.menu_categories.map((el, i) => (
              <CategoryCard key={i} name={el.name} image={el.image} id={el.id} />
            ))}
          </div>
        )}
      </div>
      <Touchbar tipsLink={data?.qrLink} />
    </main>
  );
};

export default Categories;
