import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowDown } from '../../static/arrow_down.svg';
import ClickAwayListener from '../ClickAwayListener';

interface IProps {
  options: {
    title: string;
    value: string;
  }[];
  onChange: (value: string) => void;
  className?: string;
  dropdownStyle?: string;
  placeholder?: string;
  icon?: ReactNode;
  initialValue?: {
    title: string;
    value: string;
  };
  isSplited?: boolean;
}

export const Select: FC<IProps> = ({
  options,
  onChange,
  className,
  dropdownStyle,
  placeholder,
  icon,
  initialValue,
  isSplited = false,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState(
    initialValue ? initialValue : placeholder ? { title: '', value: placeholder } : options[0]
  );
  const handleClick = () => {
    setIsOpen(prev => !prev);
  };
  /* eslint-disable-next-line */
  const handleClose = (e: any) => {
    if (e.target.classList.contains('away')) return;
    setIsOpen(false);
  };
  const handleChange = (opt: { title: string; value: string }) => () => {
    if (opt.value === placeholder) return;
    setIsOpen(false);
    setCurrentOption(opt);
    onChange(opt.value);
  };

  return (
    <div
      className={`w-[19.01vw] px-[1.042vw] py-[0.573vw] shadow-pass rounded-[0.417vw] relative
      md:w-[40.234vw] md:px-[2.604vw] md:py-[1.432vw] md:rounded-[1.042vw] ${
        isOpen && 'border-mainOrange'
      }
      xs:w-[89.333vw] xs:px-[5.333vw] xs:py-[2.933vw] xs:rounded-[2.133vw] ${className}`}
    >
      <button
        className="flex items-center away w-full h-full justify-between"
        onClick={handleClick}
      >
        <p
          className={`${
            currentOption.value === placeholder ? 'text-textGray' : 'font-bold text-mainBlack'
          } leading-[1.25vw] text-[0.833vw]  away text-ellipsis overflow-hidden whitespace-nowrap
          md:text-[2.083vw] md:leading-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw]`}
        >
          {currentOption.value === placeholder
            ? placeholder
            : isSplited
            ? `${currentOption.title.split(' ')[0]} ${t(currentOption.title.split(' ')[1])}`
            : t(currentOption.title)}
        </p>
        <ArrowDown
          className={`w-[1.25vw] h-[1.25vw] transition-transform away ${
            isOpen ? 'rotate-[180deg]' : 'rotate-0'
          } md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]`}
          stroke="black"
          fill="black"
          style={{ '&>path': { fill: 'black' } }}
        />
      </button>
      {isOpen && (
        <ClickAwayListener onClickAway={handleClose}>
          <ul
            className={`w-full bg-white absolute -bottom-[0.208vw] left-0 translate-y-full shadow-pass rounded-[0.417vw] z-10
            md:rounded-[1.042vw] md:-bottom-[0.521vw] xs:rounded-[2.133vw] xs:-bottom-[1.067vw] ${dropdownStyle}`}
          >
            {placeholder && (
              <li
                onClick={handleChange({ title: '', value: placeholder })}
                className={`cursor-pointer md:text-[2.083vw] text-textGray text-ellipsis overflow-hidden whitespace-nowrap
                md:leading-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw] py-[0.573vw] px-[1.042vw]
                md:px-[2.604vw] md:py-[1.432vw] xs:px-[5.333vw] xs:py-[2.933vw]
                border-b-[1px]`}
              >
                {placeholder}
              </li>
            )}
            {options.map(opt => (
              <li
                key={opt.value}
                onClick={handleChange(opt)}
                className={`cursor-pointer md:text-[2.083vw] text-mainBlack text-ellipsis overflow-hidden whitespace-nowrap
                md:leading-[3.125vw] xs:text-[4.267vw] xs:leading-[6.4vw] py-[0.573vw] px-[1.042vw]
                md:px-[2.604vw] md:py-[1.432vw] xs:px-[5.333vw] xs:py-[2.933vw]
                border-b-[1px] ${icon && 'flex gap-4'} hover:bg-mainYellow`}
              >
                {icon}
                {isSplited
                  ? `${opt.title.split(' ')[0]} ${t(opt.title.split(' ')[1])}`
                  : t(opt.title)}
              </li>
            ))}
          </ul>
        </ClickAwayListener>
      )}
    </div>
  );
};
